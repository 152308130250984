import { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';

import Button, { ButtonIconPositions, ButtonIconTypes, ButtonStyles } from 'components/layout/Button';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutProductSelectHorizontal: FunctionComponent<Props> = ({
    defaultOption,
    options,
    placeholder,
    onChange,
    className,
    value,
    disabled,
}) => {
    const [selectedOption, setSelectedOption] = useState<any>(defaultOption || options?.[0] || { value: null, label: placeholder });
    const [selectedOptionIdx, setSelectedOptionIdx] = useState<number>(0);
    const [translateValue, setTranslateValue] = useState<number>(0);

    const choosedValue = selectedOption?.value;
    const maxValue = options?.[options.length - 1]?.value;
    const widthPercent = `${Number((choosedValue / maxValue) * 100).toFixed(0)}%`;

    const oneElementHeight = 33;

    const handleOnOptionClick = (optionIdx: number): void => {
        const opt = options[optionIdx];

        setSelectedOptionIdx(optionIdx);
        setSelectedOption(opt);

        if (onChange) onChange(opt);
    };

    if (value && value?.value !== selectedOption?.value) {
        const idx = options.findIndex(opt => opt.value === value.value);

        if (idx !== -1) {
            handleOnOptionClick(idx);
            setTranslateValue(-oneElementHeight * idx);
        }
    }

    useEffect(() => {
        if (!defaultOption) return;

        const defaultOptionIdx = options.findIndex(option => option.value === defaultOption.value);
        setSelectedOptionIdx(defaultOptionIdx);
        setTranslateValue(-defaultOptionIdx * oneElementHeight);
    }, [defaultOption]);

    return (
        <StyledComponent className={classnames('public-layout-product-select-horizontal', className, {
            disabled: disabled,
        })}
        >
            <div className="progress-wrapper">
                <div className="progress-wrapper__content">
                    <Button
                        style={ButtonStyles.None}
                        className="add-button"
                        onClick={() => {
                            if (selectedOptionIdx > 0) {
                                handleOnOptionClick(selectedOptionIdx - 1);
                                setTranslateValue(prevState => prevState + oneElementHeight);
                            }
                        }}
                        icon={{
                            type: ButtonIconTypes.Element,
                            value: (
                                <img src="/theme/images/product-select-horizontal/subtract.svg" />
                            ),
                            position: ButtonIconPositions.Before,
                        }}
                    />
                    {!disabled && (
                        <div
                            className={classnames('progress-wrapper__scroller scroller', {
                                'scroller--decimal': !isNaN(choosedValue) && choosedValue >= 10,
                            })}
                            style={{ transform: `translateY(${translateValue}px)` }}
                        >
                            {options?.map(option => (
                                <div
                                    key={option.value}
                                    className="scroller__item"
                                >
                                    {option.value}
                                </div>
                            ))}
                        </div>
                    )}
                    {disabled && (
                        <div className="disabled-scroller-placeholder">
                            1
                        </div>
                    )}
                    <Button
                        style={ButtonStyles.None}
                        className="subtract-button"
                        onClick={() => {
                            if (selectedOptionIdx < options.length - 1) {
                                handleOnOptionClick(selectedOptionIdx + 1);
                                setTranslateValue(prevState => prevState - oneElementHeight);
                            }
                        }}
                        icon={{
                            type: ButtonIconTypes.Element,
                            value: (
                                <img src="/theme/images/product-select-horizontal/add.svg" />
                            ),
                            position: ButtonIconPositions.Before,
                        }}
                    />
                </div>
                <div
                    className={classnames('progress-wrapper__background', {
                        'bg-green': choosedValue === maxValue,
                    })}
                    style={{ transform: `translateX(${widthPercent})` }}
                />
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutProductSelectHorizontal;
